body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f5f5f9 !important;
	color: #697a8d !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
.card {
	box-shadow: 0 2px 6px 0 rgb(67 89 113 / 12%) !important;
	border: 0 solid #d9dee3 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 1rem;
	font-weight: 500;
	line-height: 1.1;
	color: #566a7f;
}

.form-label {
	margin-bottom: 0.5rem;
	font-size: 0.75rem;
	font-weight: 500;
	color: #566a7f;
}

.authentication-wrapper.authentication-basic {
	max-width: 400px;
	position: relative;
	margin: 0 auto;
}
p {
	display: block;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

.logo {
	width: 3rem;
}
.side-menu-expanded {
	transform: translate3d(0, 0, 0) !important;
}
.side-menu-expanded .close.layout-menu-toggle {
	background-color: #696cff;
	border: 7px solid #f5f5f9;
	border-radius: 50%;
	color: #fff !important;
	margin-right: -1rem;
}
.layout-navbar {
	box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
}

.toggle-domains-submenu {
	float: right;
	margin-right: 0px;
}
.domain-item a.menu-link {
	padding-bottom: 0.3rem !important;
	padding-top: 0.3rem !important;
	padding-left: 3.4rem !important;
	font-size: 1rem !important;
}
.domain-item a.menu-link::before {
	left: 2.5rem !important;
}
.domains-menu {
	width: 100%;
}
.dns-table td {
	padding: 0 !important;
	font-size: 0.9rem !important;
}

i.warning {
	color: #ffab00;
}

i.success {
	color: #71dd37;
}
i.error {
	color: #ff3e1d;
}

.menu-link.active {
	font-weight: bold;
    color: #616161 !important;
}
.table-responsive {
    overflow-x: auto !important;
}